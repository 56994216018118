import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import routes from './_routes';

const router = createBrowserRouter(routes);

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}

export default function AppRouter() {
  return <RouterProvider router={router} />;
}
