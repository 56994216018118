import styled from 'styled-components';
import { mediaTablet } from 'visits-style';

interface Props {
  fluid?: boolean;
}

export default styled.div<Props>`
  width: 100%;
  margin: 0 auto;
  padding: 4rem 1rem 5rem 1rem;
  max-width: ${({ fluid }) => (fluid ? '100%' : '818px')};

  ${mediaTablet} {
    margin: 0;
    padding-top: 40px;
    max-width: 100%;
  }
`;
