/* eslint-disable no-template-curly-in-string */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { setLocale } from 'yup';

import commonEn from './locales/en/common.json';
import commonJa from './locales/ja/common.json';

const supportedLanguages = ['ja', 'en'];

const resources = {
  en: { common: commonEn },
  ja: { common: commonJa },
};

if (process.env.NODE_ENV !== 'test') {
  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      resources,
      fallbackLng: 'ja',
      supportedLngs: supportedLanguages,
      fallbackNS: 'common',
      defaultNS: 'common',
      debug: process.env.NODE_ENV === 'development',
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: true,
      },
      detection: {
        order: ['path'],
      },
    });
}

function localeMiddleware() {
  setLocale({
    mixed: {
      required: i18n.t('common:form.mixed.required'),
    },
    string: {
      email: i18n.t('common:form.string.email'),
      min: i18n.t('common:form.string.min'),
      max: i18n.t('common:form.string.max'),
    },
  });
}

i18n.on('languageChanged', localeMiddleware);
localeMiddleware();

export default i18n;
