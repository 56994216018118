import { useTranslation } from 'react-i18next';

import lazy from './lazyImport';

type NSType = 'login' | 'main' | 'result' | 'register' | 'session' | 'master' | 'tutorial' | 'onetime' | 'guide' | 'deactivate';

const localeNamespaces = {
  en: {
    login: () => import('../locales/en/login.json'),
    main: () => import('../locales/en/main.json'),
    result: () => import('../locales/en/result.json'),
    register: () => import('../locales/en/register.json'),
    session: () => import('../locales/en/session.json'),
    master: () => import('../locales/en/master.json'),
    tutorial: () => import('../locales/en/tutorial.json'),
    onetime: () => import('../locales/en/onetime.json'),
    guide: () => import('../locales/en/guide.json'),
    deactivate: () => import('../locales/en/deactivate.json'),
  },
  ja: {
    login: () => import('../locales/ja/login.json'),
    main: () => import('../locales/ja/main.json'),
    result: () => import('../locales/ja/result.json'),
    register: () => import('../locales/ja/register.json'),
    session: () => import('../locales/ja/session.json'),
    master: () => import('../locales/ja/master.json'),
    tutorial: () => import('../locales/ja/tutorial.json'),
    onetime: () => import('../locales/ja/onetime.json'),
    guide: () => import('../locales/ja/guide.json'),
    deactivate: () => import('../locales/ja/deactivate.json'),
  },
};

function useDefferedLocale(ns: NSType[] = []) {
  const { i18n } = useTranslation();
  const lng = i18n.language;
  const ready = ns.length === 0 || (
    i18n.isInitialized
    && ns.every((n) => !!i18n.store.data[lng][n])
  );
  if (ready) return;

  throw new Promise((done, rej) => {
    Promise.all(ns.map((namespace) => new Promise<void>((res) => {
      if (i18n.store.data[lng][namespace]) {
        res();
      } else {
        // @ts-ignore
        localeNamespaces[lng][namespace]().then((data) => {
          i18n.addResourceBundle(lng, namespace, data.default, true, true);
          res();
        });
      }
    })))
      .then(done)
      .catch(() => rej(new Error('load locale failed')));
  });
}

export default function loadWithLocale(cb: () => any, ns: NSType[] = []) {
  const Component = lazy(cb);
  function LocaleComponent(props: any) {
    useDefferedLocale(ns);
    return <Component {...props} />;
  }
  return LocaleComponent;
}
