import { IS_PRODUCTION } from 'config/constants';

const isProduction = (
  IS_PRODUCTION
  && window.location.host.indexOf('stg') === -1
  && window.location.host.indexOf('dev') === -1
);

if (isProduction) {
  // @ts-ignore
  window.gtag('config', 'UA-70873442-5');
  // @ts-ignore
  window.gtag('config', 'AW-650803341');
}

export function trackPage() {
  if (isProduction) {
    // @ts-ignore
    window.gtag('event', 'page_view');
  }
}

export function trackEvent(type: string, meta?: any) {
  if (isProduction) {
    // @ts-ignore
    window.gtag('event', type, meta);
  }
}
