import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Title = styled.h1`
  color: ${({ theme }) => theme.textHead};
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 2rem;
`;

export default Title;

interface Props {
  className?: string;
  label: string;
  canonical?: string;
  alternate?: { lang: 'ja' | 'en'; url: string; };
  hidden?: boolean;
  noTitle?: boolean;
}

export function HeaderTitle({
  className, label, hidden, canonical, alternate, noTitle,
}: Props) {
  const { t } = useTranslation();

  const basePath = useMemo(() => {
    const { host, protocol } = window.location;
    return `${protocol}//${host}`;
  }, []);

  useEffect(() => {
    if (noTitle) return;

    const prevTitle = document.title;
    if (!label) {
      document.title = t('common:title');
    } else {
      document.title = [label, t('common:title')].join(' - ');
    }

    return () => {
      document.title = prevTitle;
    };
  }, [label, t, noTitle]);

  useEffect(() => {
    if (!canonical) return;

    const elem = document.createElement('link');
    elem.rel = 'canonical';
    elem.href = [basePath, canonical].join('');

    document.head.appendChild(elem);

    return () => {
      document.head.removeChild(elem);
    };
  }, [canonical, basePath]);

  useEffect(() => {
    if (!alternate) return;

    const elem = document.createElement('link');
    elem.rel = 'alternate';
    elem.hreflang = alternate.lang;
    elem.href = [basePath, alternate.url].join('');

    document.head.appendChild(elem);

    return () => {
      document.head.removeChild(elem);
    };
  }, [alternate, basePath]);

  if (hidden) return null;
  return <Title className={className}>{label}</Title>;
}
