import { useCallback } from 'react';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { unstable_batchedUpdates } from 'react-dom';
import { nanoid } from 'nanoid';

export interface UIState {
  toast: ToastInnerType | null;
  loading: boolean;
  maintenance: boolean;
  modal: ModalType | null;
}

export const ui = create(subscribeWithSelector(() => ({
  toast: null,
  loading: false,
  maintenance: false,
  modal: null,
} as UIState)));

export const useUI = ui;
export default ui;

export function useLoading() {
  return useUI(useCallback((s) => s.loading, []));
}

export function setMaintenance() {
  unstable_batchedUpdates(() => {
    ui.setState({ maintenance: true });
  });
}

export function openToast(item: ToastType) {
  unstable_batchedUpdates(() => {
    ui.setState({ toast: { id: nanoid(), ...item } });
  });
}

export const modal = {
  open: (data: ModalType) => unstable_batchedUpdates(() => {
    ui.setState({ modal: data });
  }),
  loading: (loading: boolean) => unstable_batchedUpdates(() => {
    ui.setState((state) => ({ modal: { ...(state.modal ?? {}), loading } }));
  }),
  close: () => unstable_batchedUpdates(() => {
    ui.setState({ modal: null });
  }),
};

export function openLoading(loading: boolean) {
  unstable_batchedUpdates(() => {
    ui.setState({ loading });
  });
}
