import { Suspense } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';

import Loading from 'components/Loading';
import Footer from 'components/Footer';
import Header from 'components/Header';
import GDPR from 'components/GDPR';
import Deprecated from 'components/Deprecated';
import Tracker from 'components/Tracker';

import GlobalStyle from 'style/global';

import AppProvider from '../../providers';

export default function RootOutlet() {
  return (
    <AppProvider>
      <GlobalStyle />
      <Header />
      <Deprecated />
      <Tracker />
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
      <Footer />
      <GDPR />
      <ScrollRestoration />
    </AppProvider>
  );
}
