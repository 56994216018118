/* eslint-disable import/imports-first */
import './polyfills';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import App from './app';
import { initialize } from './utils/logs';

const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);
root.render(<StrictMode><App /></StrictMode>);

initialize();

// 意図的に出すエラーメッセージは無視するように対応
window.onerror = (e: any) => {
  if (typeof e === 'string') return e.indexOf('[dtt-error]') > -1;
  if (e?.message) return e.message.indexOf('[dtt-error]') > -1;
  return false;
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
