import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Spinner } from 'visits-style';
import { useTranslation } from 'react-i18next';

import { REQUEST_TIMEOUT } from 'config/constants';

import Centered from './Centered';

export default function Loading({ error }: any) {
  const [isError, setError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const timeout = window.setTimeout(() => { setError(true); }, REQUEST_TIMEOUT / 2);
    return () => { clearTimeout(timeout); };
  }, []);

  return (
    <Container>
      <Centered data-testid="loading">
        <Spinner color="primary" size="6.5rem" />
        {(error || isError) && (
          <div className="message">
            {t('error.loading')}
          </div>
        )}
      </Centered>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 13rem);

  .message {
    margin-top: 1.25rem;
    text-align: center;
    white-space: pre-wrap;
  }
`;
