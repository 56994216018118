import { useTranslation } from 'react-i18next';

import Error from 'components/Error';
import localeRoute from 'utils/localeRoute';

export default function Maintenance() {
  const { t } = useTranslation();
  return (
    <Error
      header="Error"
      title="Error"
      description={t('error.maintenance')}
      redirect={localeRoute('/main')}
      data-testid="maintenance"
    />
  );
}
