import { useCallback, useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { Toast } from 'visits-style';
import { FiCheckCircle, FiAlertCircle, FiX } from 'react-icons/fi';
import { transparentize } from 'polished';

import ui, { UIState } from 'models/ui';
import { useResizeHandler } from 'hooks/useMobile';

type ToastType = GetComponentProps<typeof Toast>['toasts'][number];

export default function ToastProvider() {
  const isMobile = useResizeHandler(525);
  const [data, setData] = useState<ToastType[]>([]);

  const handleClear = useCallback((id: string) => setData((prev) => {
    const idx = prev.findIndex((item) => item.id === id);
    if (idx === -1) return prev;

    const arr = [...prev];
    arr.splice(idx, 1);
    return arr;
  }), []);

  useEffect(() => ui.subscribe(
    (s) => s.toast,
    (item: UIState['toast']) => {
      if (!item) return;
      const toast = {
        id: item.id,
        message: (
          <ToastItem className={item.type} data-testid="toast-item">
            <IconArea>
              {item.type === 'success' ? <SuccessIcon /> : null}
              {item.type === 'error' ? <ErrorIcon /> : null}
            </IconArea>
            <MessageArea>
              <p>{item.title}</p>
              {item.message && <small>{item.message}</small>}
            </MessageArea>
            {item.duration === null && (
              <CloseButton type="button" onClick={() => handleClear(item.id)}>
                <FiX />
              </CloseButton>
            )}
          </ToastItem>
        ),
        color: 'transparent',
        duration: getDuration(item.duration),
      } as ToastType;
      setData((prev) => [...prev, toast]);
    },
  ), [handleClear]);

  return (
    <ToastNotification
      clear={handleClear}
      toasts={data}
      position="bottom-left"
      margin={isMobile ? '0' : '1rem'}
      fixed
    />
  );
}

function SuccessIcon() {
  const theme = useTheme() as any;
  return <FiCheckCircle color={theme.primary} />;
}

function ErrorIcon() {
  const theme = useTheme() as any;
  return <FiAlertCircle color={theme.danger} />;
}

function getDuration(value?: number | null) {
  if (value === null) return null;
  if (import.meta.env.MODE === 'test') return 1;
  return value || 5000;
}

const ToastItem = styled.div`
  position: relative;
  display: flex;
  padding: 1rem;
  padding-left: 20px;
  padding-right: 50px;
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.backgroundCard};
  border-radius: ${({ theme }) => theme.radius};
  box-shadow: ${({ theme }) => theme.boxShadow};

  @media screen and (max-width: 525px) {
    padding-bottom: 1.5rem;
  }
`;

const ToastNotification = styled(Toast)`
  z-index: 10020;
  left: 80px;
  bottom: 32px;

  > div {
    position: relative;
    padding: 0;
    margin: 0;
    margin-bottom: 16px;

    & > button {
      top: 8px;
      right: 8px;
      bottom: unset;
      width: auto;
      line-height: 0;
      padding: 2px;
      margin: 0;

      svg {
        width: 10px;
        height: 10px;
      }
    }
  }

  @media screen and (max-width: 525px) {
    left: 0;
    right: 0;
    bottom: 0;
    & > div {
      width: 100%;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
        ${ToastItem} {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
`;

const IconArea = styled.div`
  padding-right: 10px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

const MessageArea = styled.div`
  p {
    display: block;
    line-height: 1.5;
  }

  small {
    display: block;
    font-size: 12px;
    line-height: 1.5;
    padding-top: 8px;
    white-space: pre-wrap;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0.725rem;
  right: 0.725rem;
  background: transparent;
  outline: none;
  border: 0;
  border-radius: ${({ theme }) => theme.radius};
  padding: 6px;
  line-height: 0;
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.text};
  }

  &:hover {
    background: ${({ theme }) => transparentize(0.65, theme.gray)};
  }
`;
