import { useRouteError } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type { PageDataError } from 'hooks/usePageData';
import ErrorPage from 'components/Error';

import Maintenance from './503';
import NotFound from './404';
import UnAuthorized from './401';

export default function RouteError() {
  const { t } = useTranslation();
  const error = useRouteError() as PageDataError;

  if (error?.errorFallback) return <ErrorPage {...error.errorFallback} />;
  if (error?.errorStatus === 503) return <Maintenance />;
  if (error?.errorStatus === 404) return <NotFound />;
  if (error?.errorStatus === 401) return <UnAuthorized />;
  // 400エラーは各ページことにエラーの表示の仕様が別々なので、NotFoundとしてfallbackしておく
  // errorFallbackとして対応することを想定する
  if (error?.errorStatus === 400) return <NotFound />;

  if (
    error?.message && (
      // ページ遷移時のネットワーク失敗
      error.message.indexOf('Failed to fetch dynamically imported module') > -1
      // 旧バージョンのページの読み込み時に、キャッシュが残ってない場合のエラー
      // 'text/html' is not a valid JavaScript MIME type.
      || error.message.indexOf('text/html') > -1
    )
  ) {
    return (
      <ErrorPage
        header="System Error"
        title="Error"
        description={t('error.pageLoad')}
        redirect={() => window.location.reload()}
        buttonText={t('btn.reload')}
        data-testid="system-error"
      />
    );
  }

  return (
    <ErrorPage
      header="System Error"
      title="Error"
      description={t('error.system')}
      redirect="login"
      data-testid="system-error"
    />
  );
}
