/* eslint-disable max-len */
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';

import { COOKIE_CONSENT_KEY } from 'config/constants';

import Container from './Container';

export default function GDPR() {
  const { i18n } = useTranslation();
  const [show, setShow] = useState(() => {
    const isAgreed = localStorage.getItem(COOKIE_CONSENT_KEY);
    if (isAgreed) return false;
    return true;
  });

  const isEnglish = i18n.language !== 'ja';

  const handleAgree = useCallback(() => {
    localStorage.setItem(COOKIE_CONSENT_KEY, '1');
    setShow(false);
  }, []);

  return (
    <Transition
      in={show}
      timeout={350}
      unmountOnExit
    >
      {(state) => (
        <Popup className={state}>
          <Container>
            {isEnglish ? (
              <p>
                This website uses cookies to improve the user-friendliness and effectiveness of the site.
                You can change your cookie preferences at any time through your browser settings.
                Please see our
                {' '}
                <a href="https://visits.world/en/cookie" target="_blank" rel="noopener noreferrer">Cookie Policy</a>
                {' '}
                for further details.
              </p>
            ) : (
              <p>
                当Webサイトでは、サイトの利便性、有効性の向上を目的にクッキーを使用しています。
                ブラウザの設定によりクッキーの機能を変更することもできます。詳細は
                <a href="https://visits.world/cookie" target="_blank" rel="noopener noreferrer">クッキーポリシー</a>
                を参照ください。
              </p>
            )}
            <AgreeButton onClick={handleAgree}>
              {isEnglish ? 'Accept & close' : '同意して閉じる'}
            </AgreeButton>
          </Container>
        </Popup>
      )}
    </Transition>
  );
}

const Popup = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.12);
  background: rgba(255,255,255,0.8);

  ${Container} {
    display: flex;
    align-items: center;
    max-width: 1264px;
    padding: 28px;
  }

  p {
    margin-right: 64px;
    font-size: 16px;
    line-height: 1.5;
  }

  a {
    cursor: pointer;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }

  will-change: transform, opacity;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-duration: 200ms;

  &.entered {
    transform: translateY(0);
  }
  &, &.entering, &.exiting, &.exited {
    transform: translateY(120%);
  }

  @media screen and (max-width: 1024px) {
    ${Container} {
      display: block;
      padding: 24px 16px 40px 16px;
    }

    p {
      font-size: 14px;
      margin-right: 0;
      margin-bottom: 24px;
    }
  }
`;

const AgreeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  height: 48px;
  cursor: pointer;
  background: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.primary};
  border-radius: 5px;
  transition: background-color 250ms cubic-bezier(0.25,0.1,0.25,1);
  font-size: 14px;

  &:hover {
    background: ${({ theme }) => theme.grayLighter};
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;
