import styled from 'styled-components';
import { mediaTablet } from 'visits-style';

export default styled.div`
  padding: 1.25rem 3rem;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.backgroundCard};
  border-radius: ${({ theme }) => theme.radius};
  width: 100%;

  ${mediaTablet} {
    padding: 24px 16px;
    min-height: 240px;
  }
`;
