import { useCallback } from 'react';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { unstable_batchedUpdates } from 'react-dom';
import { parseISO, compareAsc } from 'date-fns';

import { setUserInfo, removeUserInfo, getUserInfo } from 'utils/session';
import { configUser } from 'utils/logs';

export interface UserState {
  id?: string;
  name?: string;
  token?: string;
  refreshToken?: string;
  exp?: number;
  onetimeToken?: string;
  ticket: {
    data: { [id: string]: string; };
    stacks: string[];
    currentId: string;
  };
}

export interface TicketType {
  id: number;
  expired_at: string;
}

export const user = create(subscribeWithSelector<UserState>(() => {
  const { nickname, last_name, first_name, ...rest } = getUserInfo() ?? {};
  if (!rest.name) {
    rest.name = nickname || `${last_name || ''}${first_name || ''}`;
  }
  return { ...rest, ticket: { data: {}, stacks: [], currentId: '' } };
}));
export const useUser = user;
export default user;

export function useIsLogined() {
  return useUser(useCallback((s) => !!s.token, []));
}

user.subscribe((state, prev) => {
  if (state.token !== prev.token) {
    try {
      const uid = (
        state.token ? JSON.parse(atob(state.token.split('.')[1]))?.data?.user_id : null
      );
      configUser(uid || null);
    } catch (e) {
      // ignore
    }
  }
  const { ticket, ...rest } = state;
  setUserInfo(JSON.stringify(rest));
});

export function setTickets(tickets: Array<TicketType>) {
  const ticket = { data: {}, stacks: [], currentId: '' } as UserState['ticket'];
  const ticketList = tickets.sort(
    (a, b) => compareAsc(parseISO(a.expired_at), parseISO(b.expired_at)),
  );
  ticket.data = ticketList.reduce((acc, cur) => ({
    ...acc,
    [cur.id]: cur.expired_at,
  }), {});
  ticket.stacks = ticketList.map((item) => String(item.id));
  ticket.currentId = ticket.stacks.shift() || '';
  unstable_batchedUpdates(() => {
    user.setState({ ticket });
  });
}

interface NameParams {
  last_name: string;
  first_name: string;
  nickname?: string | null;
}

export function setUser(data: Partial<UserState>) {
  unstable_batchedUpdates(() => {
    user.setState(data);
  });
}

export function updateName({ last_name, first_name, nickname }: NameParams) {
  const name = nickname || `${last_name || ''}${first_name || ''}`;
  unstable_batchedUpdates(() => {
    user.setState({ name });
  });
}

export function clearUser() {
  removeUserInfo();
  unstable_batchedUpdates(() => {
    user.setState({}, true);
  });
}
