import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { mediaTablet } from 'visits-style';

import Container from './Container';
import Button from './Button';
import { HeaderTitle } from './HeaderTitle';
import Box from './Box';

type RedirectCallbackType = () => void;

export interface ErrorProps {
  header: string;
  title: string | React.ReactNode;
  description?: string;
  buttonText?: string;
  redirect?: string | { pathname: string; state?: any; } | RedirectCallbackType;
  noTitleChange?: boolean;
  onClick?: () => void;
  'data-testid'?: string;
}

interface InnerErrorPageType extends Omit<ErrorProps, 'redirect' | 'buttonText'> {
  footer: React.ReactNode;
}

export function InnerErrorPage({
  title, header, description, onClick, noTitleChange, footer, ...rest
}: InnerErrorPageType) {
  return (
    <Container {...rest}>
      <HeaderTitle
        label={header}
        noTitle={noTitleChange}
      />
      <Content>
        <Status>{title}</Status>
        {description && (
          <Description>{description}</Description>
        )}
        {footer}
      </Content>
    </Container>
  );
}

export default function ErrorPage({ redirect, buttonText, onClick, ...rest }: ErrorProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const clickRef = useRef(onClick);

  useEffect(() => {
    clickRef.current?.();
  }, [pathname]);

  clickRef.current = onClick;

  let footer = <div className="no-button" />;
  if (redirect && typeof redirect === 'function') {
    footer = (
      <Button
        onClick={redirect}
        mode="primary"
        size="large"
      >
        {buttonText || t('btn.back')}
      </Button>
    );
  }
  if (redirect && typeof redirect !== 'function') {
    footer = (
      <Button
        as={Link}
        to={redirect}
        mode="primary"
        size="large"
      >
        {buttonText || t('btn.back')}
      </Button>
    );
  }

  return (
    <InnerErrorPage
      {...rest}
      onClick={onClick}
      footer={footer}
    />
  );
}

const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 3rem;
  padding-top: 7.185rem;

  ${Button}, .no-button {
    margin-top: 4.685rem;
  }

  .no-button {
    height: 48px;
  }

  ${mediaTablet} {
    padding: 16px;
    padding-top: 4.5rem;
    padding-bottom: 40px;
  }
`;

const Status = styled.h1`
  font-size: 4.625rem;
  line-height: 0.75;
  color: ${({ theme }) => theme.primary};
`;

const Description = styled.p`
  font-size: 0.875rem;
  padding-top: 1rem;
  text-align: center;
`;
