import i18n from 'i18next';

export const APP_VERSION = '5.10.0';

export const SERVICE_TITLE = 'Design Thinking Test';

export const MAX_DURATION = 500;

export const SESSION_TOKEN_ID = '__@@_AUTH_TOKEN__';

export const SESSION_USER_INFO = '__@@_USER_INFO__';

export const COOKIE_CONSENT_KEY = 'cookie_consent_agree';

export const API_PATH = import.meta.env.MODE === 'test' ? 'http://localhost:3000/api' : '/api';

export const CODE_WAITING_TIME = 60;

export const IDEA_MIN_LENGTH = 80;
export const IDEA_MAX_LENGTH = 480;

export const DATE_FORMAT = i18n.language === 'en' ? 'yyyy/MMM/dd HH:mm' : 'yyyy/MM/dd HH:mm';
export const DATE_FORMAT_DAY = i18n.language === 'en' ? 'yyyy/MMM/dd' : 'yyyy/MM/dd';
export const DATE_FORMAT_SHORT = i18n.language === 'en' ? 'MMM/dd HH:mm' : 'MM/dd HH:mm';

export const REQUEST_TIMEOUT = 15000;
export const REQUEST_REFRESH_INTERVAL = 60 * 1000;

export const IS_SAFARI = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export const IS_CHROME = /chrome/i.test(navigator.userAgent);
export const IS_IE = /MSIE|Trident/i.test(navigator.userAgent);
export const IS_PRODUCTION = (
  process.env.NODE_ENV === 'production'
  && window.location.host.indexOf('design-thinking') === 0
);
export const REDIRECT_WHITELIST = [
  '/apply',
  '/en/apply',
  '/submission',
  '/en/submission',
  '/connect',
  '/en/connect',
];
