import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import { UIState, modal, ui } from 'models/ui';
import Button from 'components/Button';

export default function ModalProvider() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [config, setConfig] = useState<UIState['modal']>(null);
  const { pathname } = useLocation();

  useEffect(() => ui.subscribe(
    (state) => state.modal,
    (conf: UIState['modal']) => {
      if (conf) {
        setConfig(conf);
        if (import.meta.env.MODE === 'test') {
          setShow(true);
        } else {
          requestAnimationFrame(() => {
            setShow(true);
          });
        }
      } else {
        setShow(false);
        if (import.meta.env.MODE === 'test') {
          setConfig(null);
        } else {
          setTimeout(() => {
            setConfig(null);
          }, 250);
        }
      }
    },
  ), []);

  useEffect(() => modal.close(), [pathname]);

  if (!config) return null;

  const { content, header, footer, ...rest } = config;

  return (
    <Modal
      show={show}
      closeModal={modal.close}
      style={{ zIndex: 10010 }}
      {...rest}
    >
      {header ? <header>{header}</header> : null}
      <Content content={content} />
      {footer !== false ? (
        <footer>
          {(
            footer
            ?? (
              <Button mode="ghost" onClick={modal.close} size="large">
                {t('common:btn.close')}
              </Button>
            ))}
        </footer>
      ) : null}
    </Modal>
  );
}

function Content({ content }: { content: React.ReactNode; }) {
  if (typeof content === 'string') return <p data-testid="modal-message">{content}</p>;
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{content}</>;
}
