import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';

const easeInOutCirc = 'cubic-bezier(0.78, 0.14, 0.15, 0.86)';

interface Props {
  className?: string;
  show: boolean;
  onClose: () => void;
  children: any;
}

export default function Drawer({ className, show, children, onClose }: Props) {
  const [div] = useState(document.createElement('div'));

  useEffect(() => {
    document.body.appendChild(div);
    return () => {
      document.body.removeChild(div);
    };
  }, [div]);

  return createPortal(
    (
      <Transition
        in={show}
        timeout={300}
        unmountOnExit
      >
        {(state) => (
          <Wrapper className={state}>
            <DropShadow onClick={onClose} />
            <Container className={className}>
              {children}
            </Container>
          </Wrapper>
        )}
      </Transition>
    ), div,
  );
}

const DropShadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 250ms ${easeInOutCirc};
  background: ${({ theme }) => theme.backdrop};
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  background: ${({ theme }) => theme.backgroundCard};
  transition: transform 250ms ${easeInOutCirc};
  transform: translateX(-300px);
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 300;

  &.entering, &.exiting, &.exited {
    ${DropShadow} {
      opacity: 0;
    }

    ${Container} {
      transform: translateX(-300px);
    }
  }

  &.entered {
    ${DropShadow} {
      opacity: 1;
    }

    ${Container} {
      transform: translateX(0);
    }
  }
`;
