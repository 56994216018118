import { createGlobalStyle } from 'styled-components';
import { normalizeStyle, mediaTablet } from 'visits-style';

export default createGlobalStyle`
  ${normalizeStyle}

  html {
    height: 100%;
  }

  body {
    position: relative;
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: ${({ theme }: any) => theme.background};
    color: ${({ theme }: any) => theme.text};
    line-height: 1.5;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    padding-bottom: 3.45rem;
  }

  a {
    color: ${({ theme }: any) => theme.primary};
    font-weight: 500;
    transition: color 250ms cubic-bezier(0.25, 0.1, 0.25, 1);
    &:hover, &:focus, &:active {
      color: ${({ theme }: any) => theme.secondary};
    }
  }

  h1, h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h1, h2, h3, b, strong {
    font-weight: 500;
  }

  input[type="tel"], input[type="email"] {
    ime-mode: disabled;
  }

  input::-ms-clear {
    display: none;
  }   

  .mobile-only {
    display: none;
  }

  p {
    white-space: pre-wrap;
  }

  ${mediaTablet} {
    html, body {
      font-size: 14px;
    }

    body #root {
      padding-bottom: 206px;
    }

    .mobile-only {
      display: block;
    }
    .mobile-hide {
      display: none !important;
    }

    #visits-style-portals {
      div[data-testid="visits-style-shadow"] {
        z-index: 1000;
      }
      div[role="tooltip"] {
        z-index: 1001;
      }
    }
  }

  @media print {
    @page {
      margin: 0mm !important;
    }

    body {
      background-color: transparent;
      color: #000;
    }
    .noprint {
      display: none;
    }
  }
`;
