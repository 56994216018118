import React, { useState, useContext, useLayoutEffect, createContext } from 'react';

const BREAKPOINT = 872;

const context = createContext(window.innerWidth <= BREAKPOINT);

export function useResizeHandler(breakpoint: number = BREAKPOINT) {
  const [isMobile, setMobile] = useState(() => window.innerWidth <= breakpoint);

  useLayoutEffect(() => {
    function resize() {
      if (!isMobile && window.innerWidth <= breakpoint) {
        setMobile(true);
      }
      if (isMobile && window.innerWidth > breakpoint) {
        setMobile(false);
      }
    }
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [isMobile, breakpoint]);

  return isMobile;
}

export function ResponsiveProvider({ children }: { children: React.ReactNode; }) {
  const isMobile = useResizeHandler(BREAKPOINT);

  return (
    <context.Provider value={isMobile}>
      {children}
    </context.Provider>
  );
}

export default function useMobile() {
  return useContext(context);
}
