import { configUser } from 'utils/logs';

import { SESSION_USER_INFO } from '../config/constants';

export function setToken(token: string, expired?: number) {
  const userInfo = getUserInfo();
  const info = { ...userInfo, token };
  if (expired !== undefined) {
    info.exp = expired;
  }
  setUserInfo(JSON.stringify(info));
}

export function setUserInfo(userInfo: string) {
  try {
    const str = btoa(encodeURIComponent(userInfo));
    sessionStorage.setItem(SESSION_USER_INFO, str);
  } catch (e) {
    // ignore
  }
}

export function getUserInfo() {
  try {
    const str = sessionStorage.getItem(SESSION_USER_INFO);
    if (str) {
      const json = decodeURIComponent(atob(str));
      return JSON.parse(json);
    }
  } catch (e) {
    // ignore
  }
  return {};
}

export function removeUserInfo() {
  try {
    sessionStorage.removeItem(SESSION_USER_INFO);
    configUser('');
  } catch (e) {
    // ignore
  }
}
