import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Error from 'components/Error';
import localeRoute from 'utils/localeRoute';

export default function Unauthorized() {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Error
      header="Unauthorized"
      title="401"
      description={t('error.unauthorized')}
      redirect={{
        pathname: localeRoute('/login'),
        state: { redirect: location.pathname },
      }}
      data-testid="unauthorized"
    />
  );
}
