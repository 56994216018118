import React from 'react';
import { useTranslation } from 'react-i18next';

import Error from 'components/Error';
import localeRoute from 'utils/localeRoute';

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <Error
      header="Not Found"
      title="404"
      description={t('error.notFound')}
      redirect={localeRoute('/login')}
      data-testid="not-found"
    />
  );
}
