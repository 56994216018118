import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { tint } from 'polished';
import { mediaTablet } from 'visits-style';

import AlertIcon from 'components/Icons/Alert';
import CautionIcon from 'components/Icons/Caution';

const Wrapper = styled.div<{ $varient: string; }>`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  padding: 0.625rem 0.875rem;
  font-size: 0.75rem;
  line-height: 1.5;
  font-weight: 500;
  width: 100%;
  border-radius: ${({ theme }) => theme.radius};
  color: ${({ theme, $varient }) => theme[$varient]};
  border: 1px solid ${({ theme, $varient }) => theme[$varient]};
  background-color: ${({ theme, $varient }) => tint(0.95, theme[$varient])};

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: inherit;
      opacity: 0.65;
    }

    &:focus {
      color: inherit;
      outline: auto;
    }
  }

  svg {
    height: 1.25rem;
    margin-right: 0.5rem;
  }

  ${mediaTablet} {
    svg {
      height: 1.5rem;
      margin-right: 0.5rem;
    }
  }
`;

interface Props extends HTMLAttributes<HTMLParagraphElement> {
  type: AlertType['type'];
}

export default function Alert({ type, children, className, ...rest }: Props) {
  return (
    <Wrapper
      className={['alert', className].join(' ')}
      $varient={type === 'info' ? 'primary' : type}
      {...rest}
    >
      <>
        {type === 'info' ? <AlertIcon /> : null}
        {type === 'warning' ? <CautionIcon /> : null}
        {children}
      </>
    </Wrapper>
  );
}
