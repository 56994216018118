import { lazy } from 'react';

async function wait(i: number) {
  await new Promise((resolve) => {
    window.setTimeout(resolve, 2000 * (i + 1));
  });
}

function isRetryableError(message: string) {
  return message.includes('Failed to fetch dynamically imported module:');
}

async function retryImport(
  importer: () => Promise<any>,
  count: number,
  path?: string,
): Promise<any> {
  try {
    return await importer();
  } catch (e: any) {
    if (!isRetryableError(e.message) && !path) throw e;
    if (count >= 3) throw e;

    // 次のリクエストまで待っておく
    await wait(count);

    // エラーメッセージから実際のurlを取得する
    const url = new URL(
      path ? (
        path.substring(0, path.indexOf('?'))
      ) : (
        e.message
          .replace('Failed to fetch dynamically imported module: ', '')
          .trim()
      ),
    );
    // cache bustingのため、urlにtパラメータを追加する
    url.searchParams.set('t', `${+new Date()}`);

    try {
      return await import(/* @vite-ignore */url.href);
    } catch (err: any) {
      return retryImport(importer, count + 1, url.href);
    }
  }
}

export default function lazyImport(importer: () => Promise<any>) {
  return lazy(() => retryImport(importer, 0));
}
