import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import PublicRouter from 'pages';
import { ErrorBoundary } from 'utils/logs';

function Header() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const title = t('seo.title');
    const description = t('seo.description');

    document.title = title;
    document.documentElement.lang = i18n.language;

    const desc = document.getElementById('desc') as HTMLMetaElement | undefined;
    if (desc) { desc.content = description; }
  }, [t, i18n.language]);

  return null;
}

export default function App() {
  return (
    <ErrorBoundary>
      <Header />
      <PublicRouter />
    </ErrorBoundary>
  );
}
