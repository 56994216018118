import styled from 'styled-components';
import { mediaTablet } from 'visits-style';
import { useTranslation } from 'react-i18next';

import localeRoute from 'utils/localeRoute';

const Wrapper = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 1rem 2.5rem;
  color: ${({ theme }) => theme.grayDark};
  line-height: 1;

  span {
    font-size: 10px;
  }

  a {
    color: inherit;
    font-size: 12px;

    margin-right: 1.25rem;
    transition: color 150ms ease-in-out;
    will-change: color;

    &:hover, &:focus {
      color: ${({ theme }) => theme.textHead};
      opacity: 1;
    }
  }

  ${mediaTablet} {
    display: block;
    padding: 24px 20px 12px 20px;
    height: 206px;
    background: ${({ theme }) => theme.text};
    color: ${({ theme }) => theme.gray};

    a {
      display: block;
      margin-bottom: 18px;

      &:hover, &:focus {
        color: ${({ theme }) => theme.white};
      }
    }
    span {
      display: block;
      margin-top: 52px;
      text-align: center;
      color: ${({ theme }) => theme.grayDark};
    }
  }

  @media print {
    position: fixed;
    bottom: 0;
    padding: 0.85rem 2rem;
    background-color: transparent;
    & > div {
      visibility: hidden;
    }
  }
`;

const currentYear = new Date().getFullYear();

export default function Footer({ className }: { className?: string; }) {
  const { t, i18n } = useTranslation();
  return (
    <Wrapper className={className}>
      <div>
        <a
          href={i18n.language !== 'en' ? 'https://design-thinking-test.zendesk.com/hc/ja' : 'https://design-thinking-test.zendesk.com/hc/en-001'}
          target="_blank"
          rel="noreferrer noopener"
        >
          {t('menu.contact')}
        </a>
        <a
          href={localeRoute('/terms')}
          target="_blank"
          rel="noreferrer"
        >
          {t('menu.terms')}
        </a>
        <a
          href={i18n.language !== 'en' ? 'https://visits.world/privacy' : 'https://visits.world/en/privacy'}
          target="_blank"
          rel="noreferrer noopener"
        >
          {t('menu.privacy')}
        </a>
        {i18n.language !== 'en' && (
          <a
            href={localeRoute('/asct')}
            target="_blank"
            rel="noreferrer"
          >
            {t('menu.asct')}
          </a>
        )}
      </div>
      <span>
        ©
        {currentYear}
        {' '}
        VISITS Technologies Inc.
      </span>
    </Wrapper>
  );
}
