import { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

const disabledStyle = css`
  box-shadow: none;
  cursor: auto;
  pointer-events: none;
  color: ${({ theme }) => theme.white} !important;
  background-color: ${({ theme }) => theme.disabled} !important;
`;

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  mode?: 'primary' | 'ghost';
  size?: 'small' | 'large';
}

function withAttrs(props: Props) {
  return { type: props.type || 'button', role: props.role || 'button' };
}

function shouldForwardProp(name: string) {
  return name !== 'mode' && name !== 'size';
}

export default styled.button.attrs(withAttrs).withConfig({ shouldForwardProp })<Props>`
  outline: none;
  appearance: none !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  border: none;
  border-radius: ${({ theme }) => theme.radius};
  padding: 0.625rem;
  height: 48px;
  font-size: 14px;
  line-height: 1.25;
  font-weight: 500;
  white-space: pre-wrap;

  width: 100%;
  max-width: ${({ size }) => {
    if (!size) return '100%';
    if (size === 'large') return '240px';
    return '160px';
  }};

  transition-property: background-color, color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;

  ${({ mode }) => colors[mode || 'white']}

  ${({ disabled }) => (disabled ? disabledStyle : undefined)}
`;

const colors = {
  primary: css`
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.white} !important;
    box-shadow: 0px 6px 20px rgba(60, 69, 50, 0.20);

    &:hover {
      box-shadow: 0px 3px 8px rgba(60, 69, 50, 0.20);
    }

    &:focus, &:active, &.active {
      background-color: ${({ theme }) => theme.primaryHover};
      box-shadow: 0px 3px 8px rgba(60, 69, 50, 0.20);
    }
  `,
  ghost: css`
    background-color: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.primary};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.primary};

    &:hover, &:focus, &:active, &.active {
      color: ${({ theme }) => theme.primary};
      background-color: ${({ theme }) => theme.primarySub};
    }
  `,
  white: css`
    background-color: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.text};
    box-shadow: 0px 6px 20px rgba(60, 69, 50, 0.12);

    &:hover {
      color: ${({ theme }) => theme.text};
      box-shadow: 0px 3px 8px rgba(60, 69, 50, 0.12);
    }

    &:focus, &:active {
      color: ${({ theme }) => theme.text};
      background-color: ${({ theme }) => theme.grayLight};
      box-shadow: 0px 3px 8px rgba(60, 69, 50, 0.12);
    }

    &.active {
      background-color: ${({ theme }) => theme.primary} !important;
      color: ${({ theme }) => theme.white} !important;
      box-shadow: 0px 3px 8px rgba(60, 69, 50, 0.20);
    }
  `,
};
