import { defaultTheme } from 'visits-style';

const theme: typeof defaultTheme = {
  ...defaultTheme,

  media: {
    ...defaultTheme.media,
    mobile: '375px',
    tablet: '872px',
    desktop: '1160px',
    fullhd: '1280px',
  },

  fontFamily: 'Roboto,YakuHanJPs,"Noto Sans JP",-apple-system,Meiryo,sans-serif',
  radius: '5px',

  primary: '#28BFB6',
  primarySub: '#EDFAFA',
  primaryHover: '#1E8F89',

  secondary: '#1C6C7D',
  red: '#F36B6B',
  blue: '#4995BF',
  blueDark: '#2234BE',
  blueOpa: 'rgba(14, 70, 88, 0.8)',
  gradation: 'linear-gradient(110.59deg, #1CBCBB 1.51%, #74D18E 124.9%)',
  yellow: '#E8AE06',
  green: '#348F29',
  orange: '#F07300',
  purple: '#7C6AE2',
  lightBlue: '#44A3EA',
  redDark: '#BF2828',

  black: '#545454',
  white: '#FFFFFF',
  grayDark: '#A3A5A1',
  gray: '#C8C9C6',
  grayLight: '#DFDFDF',
  grayLighter: '#F1F1F1',
  grayBlue: '#E0E5EC',

  link: '#81BF49',
  danger: '#DC2E2E',
  warning: '#A60808',
  subLink: '#777777',

  text: '#525C65',
  textSub: '#69848C',
  textHead: '#314647',
  textHeadStrong: '#127877',

  placeholder: '#CCCCCC',
  border: '#C8C9C6',

  background: '#F6F7F9',
  backgroundCard: '#FFFFFF',
  backgroundCardSub: '#F7F7F7',

  disabled: '#C8C9C6',
  backdrop: 'rgba(0, 0, 0, 0.25)',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.12)',
};

export default theme;
