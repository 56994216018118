/* eslint-disable max-len, no-param-reassign */
import {
  useEffect, useMemo, useCallback, useState, useRef,
} from 'react';
import { Link, useNavigate, NavLink, useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { mediaTablet, TextButton, Accordion, PopoverRef } from 'visits-style';
import { FiChevronDown, FiExternalLink } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useSWRConfig } from 'swr';

import useMobile from 'hooks/useMobile';
import { modal } from 'models/ui';
import { clearUser, useUser } from 'models/user';

import localeRoute from 'utils/localeRoute';
import { API_PATH, REQUEST_TIMEOUT } from 'config/constants';
import { trackEvent } from 'utils/googleAnalytics';
import { logger } from 'utils/logs';

import Popover from './Popover';
import Drawer from './Drawer';
import BurderMenu from './Icons/BurgerMenu';
import Button from './Button';

const request = axios.create();

export default function Header() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMobile();
  const { mutate } = useSWRConfig();
  const { token, onetime, name } = useUser(useCallback((s) => ({
    token: s.token, onetime: s.onetimeToken, name: s.name,
  }), []));

  const [openDrawer, setOpenDrawer] = useState(false);
  const [showUserMenuMobile, setShowUserMenuMobile] = useState(false);
  const popover = useRef<PopoverRef>(null);

  const { pathname } = location;

  const toggleDrawer = () => setOpenDrawer(!openDrawer);
  const toggleCollapse = () => setShowUserMenuMobile(!showUserMenuMobile);

  const isOneTime = useMemo(() => onetime || pathname.indexOf('/onsite') > -1, [onetime, pathname]);

  const url = useMemo(() => {
    const path = i18n.language !== 'ja' ? 'en-001' : 'ja';
    return `https://design-thinking-test.zendesk.com/hc/${path}`;
  }, [i18n.language]);

  const logout = useCallback(() => {
    const performLogout = async () => {
      modal.loading(true);
      try {
        await request(`${API_PATH}/users/sign_out`, {
          method: 'DELETE',
          headers: {
            accept: 'application/json',
            token: `Bearer ${token}`,
          },
          timeout: REQUEST_TIMEOUT,
        });
      } catch (e) {
        // ignored
      }
      // clear all the caches
      mutate(() => true, undefined, { revalidate: false });

      clearUser();
      modal.close();
      const loginPath = onetime ? `/onsite/${onetime}` : '/login';
      navigate(localeRoute(loginPath));
    };

    modal.open({
      content: t('logout-message'),
      varient: 'small',
      footer: [
        <Button
          key="cancel-btn"
          type="button"
          mode="ghost"
          size="small"
          onClick={modal.close}
        >
          {t('btn.cancel')}
        </Button>,
        <Button
          key="logout-btn"
          type="button"
          mode="primary"
          size="small"
          onClick={performLogout}
        >
          {t('btn.logout')}
        </Button>,
      ],
    });
  }, [t, navigate, onetime, token, mutate]);

  useEffect(() => {
    setOpenDrawer(false);
    setShowUserMenuMobile(false);
    popover.current?.close();
  }, [pathname]);

  const redirectPath = useMemo(() => {
    if (onetime) return localeRoute(`/onsite/${onetime}`);
    if (pathname.indexOf('/onsite/') > -1) return pathname;
    return localeRoute('/main');
  }, [onetime, pathname]);

  const logoPath = i18n.language === 'ja' ? '/images/logo_horizontal.svg' : '/images/logo_horizontal_en.svg';

  return (
    <NavBar>
      <nav>
        <TextButton
          className="mobile-only"
          type="button"
          onClick={toggleDrawer}
          aria-label="open menu"
          pure
        >
          <BurderMenu />
        </TextButton>
        <UserButton>
          <Link className="brand" to={redirectPath}>
            <img
              src={logoPath}
              alt={t('title')}
            />
          </Link>
        </UserButton>
        <div className="mobile-only" />
        <UserButton className="mobile-hide">
          <MenuArea name={name} onetime={onetime} />
          {!isMobile && name && (
            <Popover
              ref={popover}
              label={(
                <AvatarButton type="button" data-testid="user-menu" style={{ marginRight: '1rem' }}>
                  <img src="/images/icon_avatar.svg" alt={name} />
                  {name}
                  <FiChevronDown />
                </AvatarButton>
              )}
              color="white"
              position="bottom-end"
            >
              <Menu>
                {!isOneTime ? (
                  <Link to={localeRoute('/modify')}>
                    {t('menu.modify')}
                  </Link>
                ) : null}
                <a href={url} onClick={(e) => e.stopPropagation()} target="_blank" rel="noreferrer noopener">
                  <span>{t('menu.faq')}</span>
                  <FiExternalLink />
                </a>
                <button
                  className="nav__item"
                  type="button"
                  onClick={logout}
                  data-testid="logout"
                >
                  {t('menu.logout')}
                </button>
              </Menu>
            </Popover>
          )}
          {(!isMobile && !name && !isOneTime) && (
            <>
              <Link
                className="header__link"
                to={localeRoute('/login')}
                state={location.state}
              >
                {t('menu.login')}
              </Link>
              <Link
                className="header__link button"
                to={localeRoute('/register')}
                state={location.state}
              >
                {t('menu.register')}
              </Link>
            </>
          )}
        </UserButton>
      </nav>
      {isMobile && (
        <DrawerMenu
          show={openDrawer}
          onClose={toggleDrawer}
        >
          <header>
            <img src={logoPath} alt={t('title')} />
          </header>
          {(!name && !isOneTime) && (
            <section className="user-area unauth">
              <Link className="register-button text-center" to={localeRoute('/register')}>
                {t('menu.register')}
              </Link>
              <Link className="text-center" to={localeRoute('/login')}>
                {t('menu.login')}
              </Link>
            </section>
          )}
          {name && (
            <section className="user-area">
              <Accordion
                header={(
                  <AvatarButton
                    type="button"
                    className={showUserMenuMobile ? 'active' : undefined}
                    onClick={toggleCollapse}
                    data-testid="user-menu"
                  >
                    <img src="/images/icon_avatar.svg" alt={name} />
                    {name}
                    <FiChevronDown />
                  </AvatarButton>
                )}
                timeout={250}
                show={showUserMenuMobile}
              >
                {!onetime ? (
                  <Link to={localeRoute('/modify')}>
                    {t('menu.modify')}
                  </Link>
                ) : null}
                <a href={url} onClick={(e) => e.stopPropagation()} target="_blank" rel="noreferrer">
                  {t('menu.faq')}
                  <FiExternalLink />
                </a>
                <button
                  className="nav__item"
                  type="button"
                  onClick={logout}
                  data-testid="logout"
                >
                  {t('menu.logout')}
                </button>
              </Accordion>
            </section>
          )}
          <MenuArea name={name} onetime={onetime} />
        </DrawerMenu>
      )}
    </NavBar>
  );
}

function MenuArea({ name, onetime }: { name?: string; onetime?: string; }) {
  const { t, i18n } = useTranslation();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const popover = useRef<PopoverRef>(null);

  const basePath = useMemo(() => {
    if (pathname.indexOf('/en/') !== 0) return pathname;
    return pathname.replace('/en/', '/');
  }, [pathname]);

  const changeLang = useCallback((lang: LangType) => {
    logger.info(`[locale] change to ${lang} from ${basePath}`);
    trackEvent('locale_changed', { lang, path: basePath });

    if (lang === 'ja') {
      navigate(basePath, { state });
    } else {
      navigate(`/${lang + basePath}`, { state });
    }
  }, [basePath, navigate, state]);

  useEffect(() => {
    popover.current?.close();
  }, [pathname]);

  const isOnSession = (
    pathname.indexOf('/creation') > -1
    || pathname.indexOf('/evaluation') > -1
  );
  return (
    <section className="menu-list noprint">
      {name && !isOnSession && (
        <>
          <NavLink
            to={localeRoute(onetime ? `/onsite/${onetime}/detail` : '/main')}
            className={({ isActive }) => (isActive ? 'active' : undefined)}
          >
            {t('menu.main', 'テスト受検 / 申込み')}
          </NavLink>
          <NavLink
            to={localeRoute(onetime ? `/onsite/${onetime}/results` : '/results')}
            className={({ isActive }) => (isActive ? 'active' : undefined)}
          >
            {t('menu.result', 'テスト結果')}
          </NavLink>
          <NavLink
            to={localeRoute('/guide')}
            className={({ isActive }) => (isActive ? 'active' : undefined)}
          >
            {t('menu.guide', '受検案内')}
          </NavLink>
        </>
      )}
      <Popover
        ref={popover}
        label={(
          <LocaleButton type="button">
            <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M8 2a6 6 0 100 12A6 6 0 008 2zM.7 8a7.3 7.3 0 1114.6 0A7.3 7.3 0 01.7 8z" fill="#525C65" />
              <path fillRule="evenodd" clipRule="evenodd" d="M.7 8c0-.4.3-.7.6-.7h13.4a.7.7 0 110 1.4H1.3A.7.7 0 01.7 8z" fill="#525C65" />
              <path fillRule="evenodd" clipRule="evenodd" d="M6 8c0 2 .8 4 2 5.6 1.2-1.6 2-3.5 2-5.6 0-2-.8-4-2-5.6A9.5 9.5 0 006 8zm2-6.7L7.5 1A10.9 10.9 0 004.7 8c0 2.6 1 5.2 2.8 7.1a.7.7 0 001 0A10.9 10.9 0 0011.3 8c0-2.6-1-5.2-2.8-7.1l-.5.4z" fill="#525C65" />
            </svg>
            <span>{i18n.language === 'ja' ? '日本語' : 'English'}</span>
          </LocaleButton>
        )}
        color="white"
        position="bottom-end"
      >
        <LocaleMenu>
          <button type="button" onClick={() => changeLang('ja')}>日本語</button>
          <button type="button" onClick={() => changeLang('en')}>English</button>
        </LocaleMenu>
      </Popover>
    </section>
  );
}

const AvatarButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  font-weight: 500;
  padding: 0;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 1.25rem;
  color: ${({ theme }) => theme.text};

  img {
    border-radius: 50%;
    padding: 3px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    margin-right: 0.85rem;
  }

  svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: auto;
    color: ${({ theme }) => theme.gray};
    transition: 150ms transform linear;
  }

  &.active svg {
    transform: rotate(180deg) translateY(50%);
  }

  ${mediaTablet} {
    svg {
      width: 18px;
      right: 27px;
    }
    padding: 16px 24px;
    padding-right: 45px;
  }

  @media print {
    img {
      box-shadow: none;
    }
  }
`;

const DrawerMenu = styled(Drawer)`
  header {
    padding: 1.5rem 1.125rem;
    img {
      height: 22px;
    }
  }

  a, .nav__item {
    display: flex;
    align-items: center;
    padding: 0.85rem 1.125rem;
    background: transparent;
    border: 0;
    border-radius: ${({ theme }) => theme.radius};
    line-height: 1;
    font-weight: normal;
    text-align: left;
    color: ${({ theme }) => theme.text};

    &:not(:first-child) {
      margin-top: 0.85rem;
    }

    &.active, &:focus, &:active {
      font-weight: 500;
      background: ${({ theme }) => theme.primarySub};
      color: ${({ theme }) => theme.primary};
    }

    &.active {
      pointer-events: none;
    }
  }

  a svg {
    width: 12px;
    margin-left: 6px;
    margin-bottom: 1px;
    color: ${({ theme }) => theme.gray};
  }

  .text-center {
    justify-content: center;
    text-align: center;
  }

  .register-button {
    font-weight: 500;
    padding: 10px;
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.white};

    &:focus, &:active {
      color: ${({ theme }) => theme.white};
      background: ${({ theme }) => theme.primaryHover};
    }
  }

  section {
    padding: 0.5rem;
  }

  section.menu-list {
    padding-top: 20px;
  }

  section.user-area {
    background: ${({ theme }) => theme.background};
    padding: 0;

    button {
      width: 100%;
    }

    &:not(.unauth) {
      a, button.nav__item {
        padding-left: 24px;
        margin: 0;
        margin-bottom: 8px;
      }
    }

    &.unauth {
      padding: 14px 16px 10px 16px;
      a {
        margin-top: 10px;
        padding: 10px;
      }
    }

    div.entered {
      height: 92px !important;
    }
  }
`;

const NavBar = styled.header`
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  height: 4.375rem;
  min-height: 4.375rem;
  background: ${({ theme }) => theme.white};

  nav {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1160px;
    height: 100%;
    margin: 0 auto;
    padding: 1rem 0.85rem;
  }

  .brand img {
    height: 28px;
  }

  section.menu-list {
    display: flex;
    align-items: center;
    margin-right: 2.625rem;
  }

  @media screen and (max-width: 980px) {
    section.menu-list {
      margin-left: 24px;
      margin-right: 0;
    }
  }

  ${mediaTablet} {
    height: 64px;

    nav {
      padding: 1.5rem 1.25rem;
      margin: 0;
      max-width: none;
    }

    .brand {
      margin-left: -1.125rem;
      img {
        height: 22px;
      }
    }
  }

  @media print {
    display: block;
    background-color: transparent;
    box-shadow: none;
    min-height: 0;
  }

  @media print and (orientation: portrait) {
    padding: 1rem;
  }

  @media print and (orientation: landscape) {
    padding: 1rem 4.25rem;
  }
`;

const Menu = styled.div`
  padding: 0;
  width: 11.5rem;

  a, button {
    display: flex;
    align-items: center;
    text-align: left;
    font-weight: normal;
    background-color: transparent;
    font-size: 14px;
    line-height: 1;
    border: 0;
    outline: 0;
    padding: 1rem;
    width: 100%;
    cursor: pointer;
    color: ${({ theme }) => theme.text};

    &:hover {
      color: inherit;
      background-color: ${({ theme }) => theme.backgroundCardSub};
      opacity: 1;
    }
  }

  a svg {
    width: 12px;
    margin-left: 6px;
    padding-bottom: 1px;
    color: ${({ theme }) => theme.gray};
  }
`;

const trans = keyframes`
  from {
    transform: scaleX(0.1);
  }
  to {
    transform: scaleX(1);
  }
`;

const UserButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.87rem;
  text-align: right;
  color: ${({ theme }) => theme.textHead};

  div[role="button"] {
    cursor: pointer;
  }

  .brand {
    min-width: 220px;
  }

  .header__link {
    font-weight: 500;
    font-size: 0.875rem;
    padding: 0.625rem 1rem;
    line-height: 1;
    color: ${({ theme }) => theme.text};
    border-radius: ${({ theme }) => theme.radius};
    margin-left: 1rem;

    &:hover {
      background: ${({ theme }) => theme.grayLight};
    }

    &.button {
      background: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.white};

      &:hover {
        background: ${({ theme }) => theme.primaryHover};
      }
    }
  }

  section {
    margin-left: 65px;
    height: 70px;

    a {
      position: relative;
      display: inline-flex;
      height: 100%;
      align-items: center;
      color: ${({ theme }) => theme.text};
      margin-right: 2.5rem;

      svg {
        width: 12px;
        margin-left: 6px;
        margin-bottom: 1px;
        color: ${({ theme }) => theme.gray};
      }

      &.active, &:hover {
        &:after {
          position: absolute;
          display: block;
          content: '';
          left: 0;
          right: 0;
          bottom: 0;
          height: 4px;
          transform-origin: center;
          will-change: transform;
          background: ${({ theme }) => theme.primary};
          animation: ${trans} 250ms forwards;
        }
      }
    }
  }

  ${mediaTablet} {
    justify-content: flex-end;
    .brand {
      min-width: 0;
    }
  }
`;

const LocaleButton = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.radius};
  width: 36px;
  height: 32px;

  span {
    display: none;
  }

  &:hover, &:focus {
    background: ${({ theme }) => theme.grayLighter};
    outline: none;
  }

  ${mediaTablet} {
    position: absolute;
    left: 20px;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 11px;
    min-width: 80px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.backgroundCardSub};

    span {
      display: inline-block;
      font-size: 12px;
      line-height: 1.5;
      color: ${({ theme }) => theme.text};
    }
  }
`;

const LocaleMenu = styled(Menu)`
  button:not(.active):hover {
    background: ${({ theme }) => theme.grayLighter};
  }

  button.active {
    background: ${({ theme }) => theme.grayLighter};
    color: ${({ theme }) => theme.text};
    cursor: default;
    pointer-events: none;
  }
`;
