/* eslint-disable import/no-extraneous-dependencies, no-restricted-globals, import/first, global-require, max-len, import/no-anonymous-default-export */
// polyfills for react app

// @ts-ignore
if (!Array.prototype.flat) require('core-js/features/array/flat');
if (!Object.entries) require('core-js/es/object/entries');
if (!Object.values) require('core-js/es/object/values');
if (!String.prototype.startsWith) require('core-js/es/string/starts-with');

export default null;
