import { ThemeProvider } from 'styled-components';

import theme from 'style/light-theme';
import { ResponsiveProvider } from 'hooks/useMobile';

import ModalProvider from './ModalProvider';
import ToastProvider from './ToastProvider';

export default function AppProvider({ children }: any) {
  return (
    <ThemeProvider theme={theme}>
      <ResponsiveProvider>
        {children}
        <ToastProvider />
      </ResponsiveProvider>
      <ModalProvider />
    </ThemeProvider>
  );
}
