/* eslint-disable react-hooks/rules-of-hooks */
import { useLocation, Navigate } from 'react-router-dom';

import { useUser, type UserState } from 'models/user';

import localeRoute from './localeRoute';

import Unauthorized from '../pages/_mod/401';
import NotFound from '../pages/_mod/404';

function getTokenInfo(state: UserState) {
  return { onetime: state.onetimeToken, token: state.token };
}

export default function withLogined(WrappedComponent: any, redirect?: string | false) {
  function privateRoute(props: any) {
    const { pathname, search } = useLocation();
    const { onetime, token } = useUser(getTokenInfo);

    // 認証が必要なページで、ワンタイムアカウントは/onsiteからしか入れないようにする
    if (!pathname.includes('/onsite/') && onetime) return <NotFound />;

    // 認証が必要なページで、ワンタイムアカウントではない場合は、ワンタイムアカウント専用のページには入られない
    // 遷移しようとしたら、トップ画面にリダイレクトする
    if (pathname.includes('/onsite/') && !onetime) {
      return <Navigate to={localeRoute(redirect || '/main')} />;
    }

    if (token) return <WrappedComponent {...props} />;
    // ログイン画面にリダイレクトされ、ログイン後に戻るケース
    if (redirect === false) {
      return (
        <Navigate
          to={localeRoute('/login')}
          state={{
            redirect: [pathname, search].join(''),
            message: [
              { key: 'private', type: 'info', message: 'default' },
            ],
          }}
        />
      );
    }
    if (redirect) return <Navigate to={localeRoute(redirect)} />;
    return <Unauthorized />;
  }
  return privateRoute;
}
