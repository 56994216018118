import { Route, Navigate, createRoutesFromElements } from 'react-router-dom';

import withLogined from 'utils/withLogined';
import withLocale from 'utils/withLocale';
import lazy from 'utils/lazyImport';

import RootOutlet from './_mod/RootOutlet';
import RouteError from './_mod/RouteError';
import NotFound from './_mod/404';

const LoginPage = withLocale(() => import('./login'), ['login']);
const RegisterPage = withLocale(() => import('./register'), ['register', 'master']);
const FindPassword = withLocale(() => import('./find_password'), ['login']);
const ResetPassword = withLocale(() => import('./reset_password/:token'), ['login']);
const TermsPage = lazy(() => import('./terms'));
const TermsEnPage = lazy(() => import('./terms/index.en'));
const ApplyPage = withLocale(() => import('./apply/:token'), ['main']);
const SubmissionPage = withLocale(() => import('./submission'), ['main']);
const OnSitePages = withLocale(() => import('./onsite/_routes'), ['main']);
const CommerceLawPage = lazy(() => import('./asct'));

const PrivacyPage = withLocale(() => import('./privacy'));
const PrivacyEnPage = withLocale(() => import('./privacy/index.en'));

const PaymentPage = withLogined(lazy(() => import('./payment/:examId')), false);
const PaymentDone = withLogined(lazy(() => import('./payment/:payId/done')), false);
const PaymentError = lazy(() => import('./payment/:payId/failed'));

const MainPage = withLogined(withLocale(() => import('./main/_routes'), ['main']), '/login');
const TutorialPage = withLocale(() => import('./tutorial'), ['session', 'tutorial']);

const ModifyPage = withLogined(withLocale(() => import('./modify'), ['register', 'master']));
const ModifyEmailPage = withLogined(withLocale(() => import('./modify/email'), ['register']));
const ModifyPhonePage = withLogined(withLocale(() => import('./modify/phone'), ['register', 'master']));

const CreationSession = withLogined(withLocale(() => import('./session/:examId/creation'), ['main', 'session']));
const CreationSessionStart = withLogined(withLocale(() => import('./session/:examId/creation/start'), ['main', 'session']));
const EvaluationSession = withLogined(withLocale(() => import('./session/:examId/evaluation'), ['main', 'session']));
const EvaluationSessionStart = withLogined(withLocale(() => import('./session/:examId/evaluation/start'), ['main', 'session']));

const PersonalResultPage = withLogined(withLocale(() => import('./results/_routes'), ['result', 'guide']));
const GuidePage = withLocale(() => import('./guide'), ['guide']);

const ConnectPage = withLogined(withLocale(() => import('./connect'), ['main']), false);
const ConnectExamPage = withLogined(withLocale(() => import('./connect/exams'), ['main']), false);
const ConnectSubmissonPage = withLogined(withLocale(() => import('./connect/submission/:token'), ['main']), false);

const DeactivatePage = withLogined(withLocale(() => import('./deactivate'), ['deactivate']));

const ConnectFailure = lazy(() => import('./connect_error'));

export default createRoutesFromElements(
  <Route element={<RootOutlet />}>
    <Route path="/en?" errorElement={<RouteError />}>
      <Route index element={<Navigate to="/login" replace />} hasErrorBoundary />
      <Route path="login" element={<LoginPage />} hasErrorBoundary />
      <Route path="register_confirmation/:token" element={<LoginPage />} hasErrorBoundary />
      <Route path="find_password" element={<FindPassword />} hasErrorBoundary />
      <Route path="reset_password/:token" element={<ResetPassword />} hasErrorBoundary />
      <Route path="register" element={<RegisterPage />} hasErrorBoundary />
      <Route path="tutorial" element={<TutorialPage />} hasErrorBoundary />
      <Route path="apply/:token" element={<ApplyPage />} hasErrorBoundary />
      <Route path="submission/:token" element={<SubmissionPage />} hasErrorBoundary />
      <Route path="onsite/:token/*" element={<OnSitePages />} hasErrorBoundary />
      <Route path="main/*" element={<MainPage />} hasErrorBoundary />
      <Route path="results/*" element={<PersonalResultPage />} hasErrorBoundary />
      <Route path="guide" element={<GuidePage />} hasErrorBoundary />
      <Route path="deactivate" element={<DeactivatePage />} hasErrorBoundary />
      <Route path="connect_error" element={<ConnectFailure />} hasErrorBoundary />
      <Route path="connect/:connect_token" hasErrorBoundary>
        <Route index element={<ConnectPage />} hasErrorBoundary />
        <Route path="exams" element={<ConnectExamPage />} hasErrorBoundary />
        <Route path="submission/:token" element={<ConnectSubmissonPage />} hasErrorBoundary />
      </Route>
      <Route path="modify" hasErrorBoundary>
        <Route index element={<ModifyPage />} hasErrorBoundary />
        <Route path="phone" element={<ModifyPhonePage />} hasErrorBoundary />
        <Route path="email" element={<ModifyEmailPage />} hasErrorBoundary />
      </Route>
      <Route path="session/:examId" hasErrorBoundary>
        <Route path="creation/start" element={<CreationSessionStart />} hasErrorBoundary />
        <Route path="evaluation/start" element={<EvaluationSessionStart />} hasErrorBoundary />
        <Route path="creation" element={<CreationSession />} hasErrorBoundary />
        <Route path="evaluation" element={<EvaluationSession />} hasErrorBoundary />
      </Route>
      <Route path="payment" hasErrorBoundary>
        <Route path=":payId/done" element={<PaymentDone />} hasErrorBoundary />
        <Route path=":payId/failed" element={<PaymentError />} hasErrorBoundary />
        <Route path=":examId" element={<PaymentPage />} hasErrorBoundary />
      </Route>
    </Route>
    <Route errorElement={<RouteError />}>
      <Route path="asct" element={<CommerceLawPage />} hasErrorBoundary />
      <Route path="terms" element={<TermsPage />} hasErrorBoundary />
      <Route path="privacy" element={<PrivacyPage />} hasErrorBoundary />
      <Route path="en/terms" element={<TermsEnPage />} hasErrorBoundary />
      <Route path="en/privacy" element={<PrivacyEnPage />} hasErrorBoundary />
      <Route path="user_manual.pdf" element={<Navigate to="guide" />} hasErrorBoundary />
      <Route path="user_manual_en.pdf" element={<Navigate to="en/guide" />} hasErrorBoundary />
    </Route>
    <Route path="*" element={<NotFound />} hasErrorBoundary />
  </Route>,
);
