import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { trackPage } from 'utils/googleAnalytics';

import i18n from '../i18n';

export default function Tracker() {
  const { pathname } = useLocation();

  useEffect(() => {
    const lng = i18n.language;
    const isEnglishPage = pathname.startsWith('/en');
    if (lng === 'ja' && isEnglishPage) {
      i18n.changeLanguage('en');
    } else if (lng === 'en' && !isEnglishPage) {
      i18n.changeLanguage('ja');
    }

    trackPage();
  }, [pathname]);

  return null;
}
