import { useMemo } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { IS_IE } from 'config/constants';

import Alert from './Alert';

export default function Deprecated() {
  const { i18n } = useTranslation();
  const url = useMemo(() => {
    let path = 'ja/articles/360016964473-推奨環境について';
    if (i18n.language !== 'ja') {
      path = 'en-001/articles/360016964473--Recommended-System-Requirements';
    }
    return `https://design-thinking-test.zendesk.com/hc/${path}`;
  }, [i18n.language]);

  if (!IS_IE) return null;
  return (
    <DeprecatedAlert type="warning">
      <Trans i18nKey="common:error.deprecatedBrowser">
        ご使用中のブラウザ（Internet Explorer）は非推奨のブラウザです。ブラウザの変更をお願いいたします。
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginLeft: '8px' }}
        >
          詳しくはこちら
        </a>
      </Trans>
    </DeprecatedAlert>
  );
}

const DeprecatedAlert = styled(Alert)`
  margin: 0 auto;
  margin-top: 2.5rem;
  margin-bottom: -1rem;
  max-width: 1136px;
`;
