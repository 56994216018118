import styled, { css } from 'styled-components';
import { mediaTablet, Modal as ModalBase, ModalProps, Spinner } from 'visits-style';

import Button from './Button';

interface Props extends ModalProps {
  varient?: 'small' | 'normal';
  loading?: boolean;
  headerTextColor?: string;
}

export default function Modal({
  children, loading, varient, headerTextColor,
  closeOnOverlay = true, show, closeModal, ...rest
}: Props) {
  return (
    <ModalBase
      show={show}
      closeModal={closeModal}
      closeOnOverlay={loading ? false : closeOnOverlay}
      padding={varient === 'small' ? '1.25rem' : '0.85rem'}
    >
      <ModalContent
        data-testid="dtt-modal"
        $varient={varient}
        $isLoading={loading}
        $headerTextColor={headerTextColor}
        {...rest}
      >
        {loading && <Spinner color="primary" size="6.5rem" data-testid="modal-loading" />}
        {!loading && children}
      </ModalContent>
    </ModalBase>
  );
}

const ModalContent = styled.div<{ $varient?: Props['varient']; $isLoading?: boolean; $headerTextColor?: string; }>`
  padding: 3.5rem 3rem;
  border: 0;
  background: ${({ theme }) => theme.backgroundCard};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
  width: ${({ $isLoading, $varient }) => ($isLoading ? 'auto' : `calc(100vw - ${$varient === 'small' ? '2.5rem' : '1.7rem'})`)};
  ${({ $varient, $isLoading }) => varients[$isLoading ? 'wide' : ($varient || 'wide')]};
  line-height: 1.5;

  header {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 2rem;
    color: ${({ theme, $headerTextColor }) => ($headerTextColor ? theme[$headerTextColor] : theme.secondary)};
  }

  footer {
    display: flex;
    margin-top: 2rem;
    padding: 0 0.875rem;
    justify-content: center;
    align-items: center;

    ${Button} {
      &:first-child:not(:last-child) {
        margin-right: 1.25rem;
      }
    }
  }
`;

const varients = {
  wide: css`
    ${mediaTablet} {
      padding: 2.5rem 2rem;
    }
  `,
  small: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2.5rem 2rem;
    max-width: 29rem;
    min-height: 240px;

    & > p {
      text-align: center;
      padding: 1.75rem 0;
    }
    ${mediaTablet} {
      padding: 2.5rem 2rem;
      min-height: 232px;
    }
  `,
  normal: css`
    position: relative;
    max-width: 45rem;
    min-height: 320px;
    padding-bottom: 136px;

    footer { 
      position: absolute;
      bottom: 54px;
      left: 2rem;
      right: 2rem;
    }

    ${mediaTablet} {
      padding: 2.5rem 2rem;
      padding-bottom: 116px;
      min-height: 0;
      footer {
        bottom: 40px;
      }
    }
  `,
};
