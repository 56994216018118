/* eslint-disable no-param-reassign */
import {
  ErrorBoundary as SentryBoundary,
  init, configureScope,
  captureMessage, captureException, addBreadcrumb,
} from '@sentry/react';
import type { CaptureContext, Breadcrumb } from '@sentry/types';

import { APP_VERSION } from 'config/constants';

const sessionCreated = new Date().toISOString();
const dsn = import.meta.env.VITE_SENTRY_DSN;

function generateElementLog(elem?: HTMLElement) {
  if (!elem) return null;

  const tag = elem.tagName?.toLowerCase() ?? 'unknown';
  const messages = [] as string[];

  if (elem.ariaLabel) {
    messages.push(`label=${elem.ariaLabel}`);
  } else if (elem.innerText) {
    let text = elem.innerText;
    text = text.replace(/\n/g, ' ');
    if (text.length > 25) {
      text = [text.substring(0, 25), '...', ',len=', text.length].join('');
    }
    messages.push(`text=${text}`);
  }

  if (elem.getAttribute?.('data-testid')) {
    messages.push(`testid=${elem.getAttribute('data-testid')}`);
  }

  if ((elem as HTMLInputElement).disabled) messages.push('disabled');

  if (!messages.length) return null;
  return [tag, ': (', messages.join(','), ')'].join('');
}

export function initialize() {
  if (import.meta.env.DEV) return;

  init({
    dsn,
    debug: !dsn,
    release: import.meta.env.VITE_SENTRY_RELEASE,
    enabled: true,
    initialScope: {
      contexts: {
        // eslint-disable-next-line camelcase
        app: { app_version: APP_VERSION, app_start_time: sessionCreated },
      },
    },
    beforeSend(event) {
      if (!event.message) return event;

      // ログインエラーはメール・パスワード間違いの場合も含めているし、不要なログの数が多く発生してしまうので、送らないようにする
      if (event.message.indexOf('sign_in') > -1 && event.message.indexOf('401') > -1) return null;
      // 意図的なエラー画面への遷移なので、unhandled Errorとして送る必要はなし
      if (event.message.indexOf('[dtt-error]') > -1) return null;
      return event;
    },
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category?.startsWith('ui') && hint?.event.target) {
        const msg = generateElementLog(hint?.event.target);
        if (msg) {
          // eslint-disable-next-line no-param-reassign
          breadcrumb.message = msg;
        }
      }

      return breadcrumb;
    },
    tracesSampleRate: 1.0,
  });
}

export function configUser(userId: string) {
  if (import.meta.env.DEV) return;

  configureScope((scope) => {
    scope.setUser({ id: userId });
  });
}

function logMessage(msg: string, ctx: Omit<Breadcrumb, 'message'> = {}) {
  if (import.meta.env.MODE === 'test') return;
  // eslint-disable-next-line no-console
  if (import.meta.env.DEV) return console.warn(msg);
  addBreadcrumb({ ...ctx, message: msg });
}

export const logger = {
  error: (msg: string | Error, ctx?: CaptureContext) => {
    if (typeof msg === 'string') {
      captureMessage(msg, ctx);
    } else {
      captureException(msg, ctx);
    }
  },
  debug: (message: string, ctx: Omit<Breadcrumb, 'message'> = {}) => logMessage(message, { ...ctx, level: 'debug' }),
  log: (message: string, ctx: Omit<Breadcrumb, 'message'> = {}) => logMessage(message, ctx),
  info: (message: string, ctx: Omit<Breadcrumb, 'message'> = {}) => logMessage(message, { ...ctx, level: 'info' }),
  warn: (message: string, ctx: Omit<Breadcrumb, 'message'> = {}) => logMessage(message, { ...ctx, level: 'warning' }),
};

export const ErrorBoundary = SentryBoundary;
